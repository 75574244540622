.case-studies-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.case-studies-container-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.case-studies-container-inner .case-studies-item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem;
  text-align: center;
  /* border: 1px solid #0002; */
  border-radius: 5px;
  overflow: hidden;
  width: 400px;
  min-height: 250px;
  box-shadow: 1px 1px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.case-studies-container-inner .case-studies-item:hover {
  transform: scale(1.08);
}

.case-studies-item img {
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

.case-studies-item h2 {
  font-size: 1.5em;
}

.case-studies-item p {
  font-size: 0.7em;
  background: #0002;
}

/* case studies popup Starts */

.case-study-popup-close {
  display: block;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.case-study-popup-container {
  display: none;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0007;
  opacity: 0;
  transform: scale(0.8);
  -webkit-animation: case-study-popup 0.3s ease-out forwards;
          animation: case-study-popup 0.3s ease-out forwards;
  overflow-y: auto;
}
.case-study-popup-container.active {
  display: block;
  opacity: 1;
}

@-webkit-keyframes case-study-popup {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes case-study-popup {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.case-study-popup-container-inner {
  z-index: 102;
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  margin: 0.5rem;
  background: var(--color-bg);
  border-radius: 5px;
  /* min-width: 25rem; */
  max-width: 100vw;
  min-height: 35rem;
  box-shadow: 1px 1px 10px 2px #0006;
}

.case-study-popup-content {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.case-study-popup-content h2 {
  text-align: center;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  border-bottom: 1px solid #0003;
}

.case-study-popup-content h3 {
  font-size: 1em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.case-study-popup-content p {
  font-size: 0.8em;
  margin-left: 2rem;
  line-height: 1.5;
}
.case-study-popup-content p a {
  text-decoration: underline;
  font-style: italic;
  color: blue;
}

@media screen and (max-width: 768px) {
  .case-study-popup-container-inner {
    left: 0;
    transform: none;
  }
}

/* case studies popup Ends*/
