.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 50px; */
  z-index: 50;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0.5rem 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.navbar-container .navbar-container-inner {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}
.navbar-container .brand-logo {
  height: 50px;
  max-height: 100%;
  align-self: flex-start;
  border-radius: 5px;
  margin: 0;
  overflow: hidden;
}

.navbar-container .brand-logo img {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.navbar-container .navbar-items-container {
  display: flex;
  padding: 0.5rem;
  color: #fff;
  flex-wrap: wrap;
}

.navbar-container .navbar-items-container a,
.navbar-container .navbar-items-container li {
  cursor: pointer;
  display: block;
  padding: 0.5rem 0.4rem;
  margin: 0 0.3rem;
  color: inherit;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.partners-container a {
  margin: 0.2rem 0.3rem !important;
  border-bottom: 2px solid #fff7;
}

.navbar-container .navbar-items-container a:hover,
.navbar-container .navbar-items-container a:focus,
.navbar-container .navbar-items-container li:hover,
.navbar-container .navbar-items-container li:focus-within {
  background-color: var(--color-primary-900);
  color: #fff !important;
  transition: all 0.3s ease-in-out;
}

.navbar-container .navbar-items-container a.cta-button {
  margin: 0 0rem 0 2rem;
}

.navbar-toggle {
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px !important;
  transition: all 0.3s ease-in-out;
  background: transparent;
  border: none;
  outline: none;
  display: none !important;
}

.navbar-toggle.show::before {
  transform: rotate(45deg);
  top: 15px;
  transition: all 0.3s ease-in-out;
}

.navbar-toggle.show span {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-toggle.show::after {
  transform: rotate(-45deg);
  top: 15px;
  transition: all 0.3s ease-in-out;
}

.navbar-toggle:before {
  content: "";
  top: 6px;
}

.navbar-toggle span {
  top: 15px;
}

.navbar-toggle:after {
  content: "";
  top: 24px;
}

.navbar-toggle:before,
.navbar-toggle span,
.navbar-toggle:after {
  width: 25px;
  height: 2px;
  display: block;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  position: absolute;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.navbar-mobile {
  flex-direction: column;
  height: 100vh;
  position: fixed;
  max-width: 60%;
  top: 66px;
  right: 0;
  background: var(--color-bg);
  color: var(--color-text) !important;
  transform: translateX(150%);
  transition: all 1s ease-in-out;
  box-shadow: none;
}

.navbar-mobile.show {
  transform: translateX(0%);
  transition: all 1s ease-in-out;
  box-shadow: -5px 0 60px 0px rgb(0 0 0 / 40%);
}

.navbar-mobile a,
.navbar-mobile li {
  margin: 0.4rem 2rem !important;
  margin-right: 4rem !important;
}

.navbar-mobile a.cta-button {
  margin: 0 1rem 0 1rem;
}

.navbar-mobile .partners-container {
  position: relative !important;
  height: auto;
  top: 0;
  height: 0;
}

.navbar-mobile li a {
  color: #fff !important;
}

.partners-container {
  position: absolute;
  top: 50px;
  left: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background: rgba(0, 0, 0, 0.7);
  color: #fff !important;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); 
  border-radius: 0 0 5px 5px;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s ease-out;
}

li.navbar-item:hover .partners-container,
li.navbar-item:focus-within .partners-container {
  height: 220px !important;
  padding: 0.5rem !important;
  color: #fff9;
}

@media screen and (max-width: 920px) {
  .navbar-toggle {
    display: block !important;
  }
  .navbar-pc {
    display: none !important;
  }
  .navbar-container-inner {
    padding: 0 4rem !important;
  }
}

@media screen and (max-width: 550px) {
  .navbar-container-inner {
    padding: 0 3rem !important;
  }
}
