.loader{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  margin: auto;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
}
