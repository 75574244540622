*,*::before,*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body{
    font-family: var(--font-family);
    scroll-behavior: smooth;
    background: var(--color-bg);
    font-size: 16px;
    overflow-x: hidden;
}