.admin-container {
  width: 100%;
}
.admin-container-inner {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.admin-items{
  padding: 2rem;
  margin: 1rem;
  width: 10rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #0002;
  text-align: center;
  border-radius: 5px;
}