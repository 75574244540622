:root {
  /* --font-family: 'Montserrat', sans-serif; */
  --font-family: "Roboto", "Montserrat", sans-serif;
  --color-primary: #dd1c4a;
  --color-primary-900: #5e0d0d;
  --color-primary-900: #700a0a;
  --color-acsent: #f99e1b;
  --color-bg: #fff;
  --color-text: #000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  scroll-behavior: smooth;
  background: var(--color-bg);
  font-size: 16px;
  overflow-x: hidden;
}
a {
  color: unset;
  text-decoration: none;
}

.cta-button {
  display: inline-block;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--color-primary);
  font-size: 1em;
  font-weight: 600;
  color: #fff !important;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.cta-button:disabled {
  background: var(--color-primary);
  transition: 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.cta-button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: var(--color-primary-900);
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.cta-button:hover,
.cta-button:focus {
  transform: scale(1.08);
  transition: 0.3s ease-in-out;
}

.cta-button:hover::before,
.cta-button:focus::before {
  width: 100%;
  transition: 0.3s ease-in-out;
}

.container-title {
  text-align: left;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
  margin-bottom: 1.5rem;
  padding-top: 6rem;
}
.container-title h2,
.title-primary {
  color: var(--color-primary-900);
  font-size: 2.5em;
  line-height: 0.65;
}
.container-title h3 {
  font-size: 1.25em;
  line-height: 1;
  margin-left: auto;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transform: translateX(60%);
}
