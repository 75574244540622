.footer-container{
    width: 100%;
    max-width: 100vw;
    height: fit-content;
    min-height: 300px;
    overflow: hidden;
    background: var(--color-primary);
    margin-top: 5rem;
    color: #fff;
    text-align: center;
    padding-bottom: 0.5rem;
}

.footer-container>p{
    font-size: 0.8em;
}

.footer-container-inner{
    padding: 3rem 2rem 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.footer-item{
    width: fit-content;
    height: 100%;
    padding: 1rem;
}

.footer-container .brand-logo{
    width: 250px;
    display: block;
    height: fit-content;
}

.brand-logo img{
    width: 100%;
    border-radius: 10px;
}
.connect > h3 {
    margin-bottom: 1rem;
    margin-top: 2rem;
    text-align: left;
}
.connect .socials-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.social-icons{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: block;
    background: #fff;
    color: blue;
    text-align: center;
    line-height: 35px;
    transition: all 0.5s ease-in-out;
}

.social-icons:hover,
.social-icons:focus{
    background: blue;
    color: #fff;
    transition: all 0.5s ease-in-out;
}

.footer-item .footer-link{
    padding: 0.5rem 0.75rem;
    margin: 0.25rem 0;
    display: block;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
}
.footer-item .footer-link:hover,
.footer-item .footer-link:focus{
    background: var(--color-primary-900) !important;
    transition: all 0.5s ease-in-out;
}

.footer-title{
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.footer-item .contact-item{
    display: flex;
    align-items: center;
    margin: 1.25rem .25rem;
}

.footer-item .contact-item:hover > .social-icons,
.footer-item .contact-item:focus-within  > .social-icons{
    background: blue;
    color: #fff;
    transition: all 0.7s ease-in-out;
}

.footer-item .contact-item .social-icons{
    margin: 0 .5rem;
}