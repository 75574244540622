.services-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.services-container-inner {
  max-width: 1000px;
  margin: 2rem auto;
  text-align: center;
}

.service-img {
  max-width: 15rem;
  margin: 1rem auto;
}

.service-title {
  text-align: center;
  font-weight: 600;
}

.services-container-inner .service-discription {
  font-weight: 300;
}

.service-details a {
  text-decoration: underline;
  color: blue;
}
