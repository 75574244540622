.notfound-container {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.back-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14rem;
  z-index: -1;
  opacity: 0.2;
}

.front-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.notfound-container p{
  margin-bottom: 2.5rem;
}