.what-container {
  /* padding-top: 6rem; */
  max-width: 100vw;
  position: relative;
  padding: 1rem;
}

.what-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
  /* height: 1px; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.what-container .what-container-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.what-container-inner .services-cont {
  /* margin: 0.5rem; */
  padding: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100vw;
}

.services-cont .services-item {
  display: flex;
  min-width: 250px;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem 0.5rem;
  padding: 1rem 0.5rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  /* box-shadow: 2px 2px 16px #0003; */
}

.services-cont .services-item:hover {
  background-color: #0001;
}

.services-cont .service-img {
  margin: auto 0;
}

.services-cont .services-item:nth-child(even) {
  flex-direction: row-reverse;
}

.services-cont .services-item img {
  width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.services-cont .services-item .service-content {
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
}

.service-title {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  font-weight: 400;
  /* color: var(--color-primary-900); */
}

.services-item .service-content .service-discription {
  width: 100%;
  /* text-transform: lowercase; */
  font-style: italic;
  font-weight: 300;
}

@media screen and (max-width: 1240px) {
  .services-cont .services-item .service-content {
    max-width: 350px !important;
  }

  .services-cont {
    border: none !important;
  }
}

@media screen and (max-width: 1240px) {
  .what-container .what-container-inner {
    flex-direction: column;
  }
}
@media screen and (max-width: 430px) {
  .what-container .what-container-inner .services-item {
    flex-direction: column;
    text-align: center;
  }
  .what-container .what-container-inner .services-item .service-img {
    margin: auto;
  }
  .what-container .what-container-inner .service-title {
    font-size: 1.25rem;
  }
}
