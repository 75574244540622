.admin-hirings-contianer .forms-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.admin-hirings-contianer form {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 30rem;
  max-width: 98vw;
  margin: 2rem;
  text-align: center;
  border: 1px solid #0002;
  border-radius: 5px;
  padding: 1rem;
}

@media screen and (max-width: 600px) {
  .admin-hirings-contianer form {
    min-width: 0 !important;
  }
}

.admin-hirings-contianer form input,
.admin-hirings-contianer form textarea {
  width: 98%;
  display: block;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  max-width: 100%;
}

.admin-hirings-inputs-select {
  margin: 0.5rem;
  text-align: left;
}

.admin-hirings-inputs-select select {
  width: 101%;
  display: block;
  margin: 1rem 0rem;
  padding: 0.5rem;
  border: 1px solid #0002;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.admin-hirings-contianer form .admin-hirings-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
  pointer-events: none;
}

.admin-hirings-inputs {
  position: relative;
}

.admin-hirings-contianer form .admin-hirings-inputs label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  background: #fff;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 300;
}

.admin-hirings-contianer form input::-webkit-input-placeholder,
.admin-hirings-contianer form textarea::-webkit-input-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input::-moz-placeholder,
.admin-hirings-contianer form textarea::-moz-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input:-ms-input-placeholder,
.admin-hirings-contianer form textarea:-ms-input-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input::-ms-input-placeholder,
.admin-hirings-contianer form textarea::-ms-input-placeholder {
  color: transparent;
}

.admin-hirings-contianer form input::placeholder,
.admin-hirings-contianer form textarea::placeholder {
  color: transparent;
}
.admin-hirings-contianer form input:placeholder-shown ~ label,
.admin-hirings-contianer form textarea:placeholder-shown ~ label {
  font-size: 1rem;
  cursor: text;
  padding: 0;
  top: 0.5rem;
  left: 1.5rem;
}

.admin-hirings-contianer form input:focus ~ label,
.admin-hirings-contianer form textarea:focus ~ label {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  padding: 0.2rem;
  display: block;
  transition: all 0.2s ease;
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
}

.admin-hirings-contianer form input[type="submit"] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.admin-hirings-contianer .upload-disclaimer {
  font-size: 0.7em;
  font-weight: 200;
}

.admin-hirings-contianer .upload-disclaimer a {
  color: blue;
}

.filepond--credits {
  display: none;
}
