.work-container {
  width: 100vw;
  /* padding-top: 6rem */
  /* overflow: hidden; */
}

.flickity-page-dots .dot {
  background: #fff !important;
    opacity: 0.5 !important;
}

.flickity-page-dots .dot.is-selected{
    opacity: 1 !important;
}

.work-container-inner {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.3rem;
  background: var(--color-primary-900);
}

.work-main-carousel {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  /* display: flex;
    padding: 1rem;
    justify-content: flex-start;
    align-items: flex-start; */
  overflow-y: scroll;
  padding: 2rem 1rem;
}

.work-main-carousel::-webkit-scrollbar {
  height: 10px;
  width: 0;
  cursor: pointer;
}

/* Track */
.work-main-carousel::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50%;
  cursor: pointer;
}

/* Handle */
.work-main-carousel::-webkit-scrollbar-thumb {
  background: rgb(161, 161, 161);
  cursor: pointer;
}

/* Handle on hover */
.work-main-carousel::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

.work-main-carousel a {
  margin: 0.5rem;
}

.work-main-carousel .carousel-cell {
  position: relative;
  align-self: center;
  display: block;
  margin: 0.25rem;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 5px #400000;
}

.work-container .carousel-cell img {
  height: 200px;
  min-width: 400px;
  -o-object-fit: fill;
     object-fit: fill;
  display: block;
}

.work-container .carousel-cell .work-info {
  padding: 0.75rem;
  width: 100%;
  height: calc(100% - 45px);
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% - 49px));
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  transition: all 0.7s ease-in-out;
}

.carousel-cell h3 {
  font-size: 25px;
  margin: 0.1rem 0;
  border-bottom: 2px solid rgba(59, 59, 59, 0.267);
}

.work-main-carousel a:hover .work-info,
.work-main-carousel a:focus-within .work-info {
  height: 100%;
  transform: translate(-50%, 0%);
}

.carousel-cell p {
  font-weight: 300;
  padding-top: 0.2rem;
  font-size: 0.8rem;
}

.work-container .our-work-btn {
  margin: 1rem auto;
  display: block !important;
  transition: all 0.3s ease-in-out;
}

.work-container .our-work-btn:hover,
.work-container .our-work-btn:focus {
  box-shadow: 0px 2px 5px #000;
  transition: all 0.3s ease-in-out;
  transform: scale(1.08);
}

.work-container .cta-button:hover::before {
  background: var(--color-primary) !important;
}
