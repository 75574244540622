.scroll-top-container {
  position: fixed;
  right: 0;
  bottom: 5vh;
  overflow: hidden;
  padding: 0.5rem;
  padding-right: 0;
}

.scroll-top-container-inner {
  cursor: pointer;
  display: block;
  background: var(--color-primary-900);
  color: #fff;
  padding: 0.75rem 1rem;
  /* padding-right: 2rem; */
  border-radius: 5px 0px 0 5px;
  box-shadow: -1px 1px 4px 0px #fff;
  font-size: 1.75rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transform: translateX(200%);
  transition: all 0.7s ease-in-out;
}

.scroll-top-container-inner.active{
  transform: translateX(0%);
  transition: all 0.7s ease-in-out;
}